import * as React from "react";
import {
  Image,
  Page,
  WixDesignSystemProvider,
  StarsRatingBar,
  Cell,
  EmptyState,
  PulseAnimation,
  Loader,
  Card,
  MarketingLayout,
  Button,
  Box,
  Text,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";

import Icon from "./title-illustration-new.svg";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  var [appId, setAppId] = React.useState("");

  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    const url = window.location.href;
    const urlSplit = url.split("/");
    const appId = urlSplit[urlSplit.length - 1];
    setAppId(appId);
    // find local storage and set value
    const value = localStorage.getItem(appId);
    if (value) {
      if (value !== "5") {
        // @ts-ignore
        setValue(parseInt(value));
      }
    }
  }, []);

  React.useEffect(() => {
    if (value === 5 && appId !== "") {
      window.open(
        `https://www.wix.com/app-market/add-review/${appId}`,
        "_self"
      );
    } else {
      if (value) {
        // @ts-ignore
        localStorage.setItem(appId, value.toString());
      }
    }
  }, [value]);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page minWidth={0} height="100vh">
        <Page.Content>
          <EmptyState
            theme="page-no-border"
            image={
              <Image width="120px" height="120px" src={Icon} transparent />
            }
            title="Share Your Experience"
            subtitle="Help us make the app even better. Let us know what you think."
          >
            {!value && (
              <Cell span={12}>
                <PulseAnimation active={true} color="B10" borderRadius="18px">
                  {/* @ts-ignore */}
                  <StarsRatingBar
                    readOnly={value && value !== 5}
                    size="large"
                    value={value}
                    onChange={setValue}
                  />
                </PulseAnimation>
              </Cell>
            )}
            {value === 5 && <Loader />}
            {value && value !== 5 && (
              <Card>
                <MarketingLayout
                  size="tiny"
                  title="We are sorry you didn't have a great experience"
                  description={
                    <Box direction="vertical" display="inline-flex" gap="SP1">
                      <Text>Can you tell us what went wrong?</Text>
                      <Button
                        id="reviews-non-5-stars-contact-support"
                        size="small"
                      >
                        Contact Priority Support
                      </Button>
                    </Box>
                  }
                />
              </Card>
            )}
          </EmptyState>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
